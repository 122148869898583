import i18next from "i18next";
import { useEffect, useState } from "react";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";
import LangSpan from "../../components/lang";

import {
  arrow_down,
  Close_Burger,
  logo,
  Open_Burger,
} from "../../assets/images";

const navbar_data = [
  { id: 0, title: "main", to: "main" },
  { id: 1, title: "departments", to: "departments" },
  { id: 2, title: "about", to: "about" },
  { id: 3, title: "contact", to: "contact" },
];

const langData = ["uz", "ru", "en"];

const Navbar = ({ setActiveIndex, activeIndex }) => {
  const [visible, setVisible] = useState(false);

  const [burger, setBurger] = useState(false);

  const [lang, setLang] = useState("uz");

  const [isOpen, setIsOpen] = useState(false);

  const { t } = useTranslation();

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 100 && window.screen.availWidth > 768) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.addEventListener("scroll", null);
    };
  }, []);

  return (
    <header
      className="bg-lightPink md:bg-transparent md:bg-black/0 fixed z-50 w-full transition-all duration-300"
      style={{ backgroundColor: `${visible ? "rgba(235, 211, 215,0.8)" : ""}` }}
    >
      <div className="relative">
        {/* navbar */}
        <nav className="container relative flex justify-between items-center min-h-[80px] md:max-h-[80px]">
          {/* logo */}
          <div className="absolute md:hidden w-[61px] h-[61px] z-20">
            <img
              src={logo}
              alt="mme_holding"
              className="w-full h-full object-cover"
              width={134}
              height={134}
            />
          </div>
          {/* nav items */}
          <ul
            className={`transition-all duration-300 absolute top-0 left-0 right-0 md:translate-y-0  w-full flex items-center flex-col md:flex-row  gap-8 pt-[80px] px-[20px] md:px-[30px] md:pt-0  md:static h-screen md:h-auto md:justify-center  ${
              burger
                ? " justify-start -translate-y-[0px] z-10 bg-[#000]/70 before"
                : "-translate-y-full bg-transparent"
            } 
          `}
          >
            {navbar_data.map((item, idx) => {
              return (
                <li
                  key={item.id}
                  className={`${activeIndex === idx ? "activeLink" : ""} py-2`}
                >
                  <Link
                    onClick={(e) => {
                      setActiveIndex(idx);
                      window.localStorage.setItem("index", idx);
                      setBurger(false);
                    }}
                    to={item.to}
                    className="flex items-center gap-[6px] uppercase hover:cursor-pointer"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <span
                      className={`hover:text-brand font-poppins tracking-[0.07em] text-sm font-semibold  ${
                        activeIndex === idx ? "text-brand" : ""
                      }`}
                    >
                      {t(`nav.${item.title}`)}
                    </span>
                  </Link>
                </li>
              );
            })}
            <li className="hidden md:block">
              <select
                className="outline-none bg-transparent text-sm font-semibold "
                name=""
                id=""
                onChange={(e) => {
                  i18next.changeLanguage(e.target.value);
                }}
              >
                <option hidden>Lng</option>
                <option value="uz">Uz</option>
                <option value="ru">Ru</option>
                <option value="en">En</option>
              </select>
            </li>
          </ul>
          {/* flag */}
          <div className="absolute flex gap-[5px] items-center justify-center w-[25px] md:hidden right-[80px] md:right-[20px] lg:right-[30px] ">
            <span
              className="font-poppins capitalize"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              {lang}
            </span>
            <span
              className={`${
                isOpen
                  ? "absolute flex flex-col top-full bg-white w-8 text-center font-poppins"
                  : "hidden"
              }`}
            >
              {langData.map((item, i) => {
                return (
                  <LangSpan key={i} setLang={setLang}>
                    {item}
                  </LangSpan>
                );
              })}
            </span>
            <img
              className="w-full h-full object-contain"
              src={arrow_down}
              alt="arrow_down"
              width={12}
              height={7}
            />
          </div>

          {/* burger */}
          <div
            onClick={(e) => setBurger(!burger)}
            className="relative z-20 w-[35px] h-[35px] ml-auto md:hidden bg-darkBrown rounded-full grid place-items-center"
          >
            <img
              className={`w-[15px] ${burger ? "scale-150" : ""}`}
              src={burger ? Close_Burger : Open_Burger}
              alt="menu opener"
              width={34}
              height={34}
            />
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
