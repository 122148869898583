import React from "react";
import i18next from "i18next";

export default function LangSpan({ children, setLang }) {
  return (
    <span
      className="active:bg-darkBrown capitalize"
      onClick={(e) => {
        i18next.changeLanguage(e.target.textContent);
        setLang(e.target.textContent);
      }}
    >
      {children}
    </span>
  );
}
