import React from 'react'

const Title = ({ children, className = '' }) => {
  return (
    <h3 className={`text-[40px] font-rische text-center lg:text-xl lg:leading-[72px] leading-[48px] text-brand ${className}`}>
      {children}
    </h3>
  )
}

export default Title