import React from "react";
import {
  aboutUs,
  aboutUsRetina,
  chamomile,
  departments_crown,
  ribbon,
  text_frame,
  threeGreenLeafs,
  threeLeafs,
  tulip,
} from "../assets/images";
import Title from "../components/title.component";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();
  return (
    <section className="about section container py-[50px]" id="about">
      <div className="relative">
        <img
          className="hidden md:block -z-10 absolute max-w-[230px] max-h-[307px] -scale-x-100  translate-x-[50%] top-0 right-0"
          src={ribbon}
          alt="ribbon"
          width={262}
          height={361}
        />

        <div className="w-full mb-[30px] md:mb-[60px]">
          <Title className="mb-[10px] md:mb-[20px] capitalize">
            {t("nav.about")}
          </Title>
          <img
            className="mx-auto"
            src={departments_crown}
            alt="departments-crown"
            width={819}
            height={43}
          />
        </div>

        <div className="flex flex-col gap-8 lg:flex-row items-center justify-center lg:gap-[35px]">
          {/* img */}
          <div className="w-full relative -z-10 max-w-[500px] md:w-1/2 md:max-w-[825px] min-h-[179px]">
            <div className="p-2 relative">
              {/* --------------- */}
              <img
                className="absolute  -top-3 -left-3
                w-[80px] sm:w-[120px] sm:-top-6 sm:-left-6 md:w-[150px] md:-top-9 md:-left-8 lg:w-[190px] lg:-top-10 lg:-left-9"
                src={text_frame}
                alt="text-frame"
                width={224}
                height={190}
              />
              <img
                className="absolute  -bottom-3 -right-3
                w-[80px] sm:w-[120px] sm:-bottom-6 sm:-right-6 md:w-[150px] md:-bottom-9 md:-right-8 lg:w-[190px] lg:-bottom-10 lg:-right-9 rotate-180"
                src={text_frame}
                alt="text-frame"
                width={224}
                height={190}
              />

              <img
                className="absolute -bottom-7 -left-7 w-[80px] md:w-[180px] md:-bottom-12 md:-left-19 "
                src={threeLeafs}
                alt="threeLeafs"
                width={193}
                height={213}
              />

              <img
                className="absolute top-0 right-1
              w-[71px] md:w-[90px]  md:top-0  md:-right-4 -z-10 translate-x-[75%] -translate-y-[20%] xl:w-[160px]"
                src={threeGreenLeafs}
                alt="threeGreenLeafs"
                width={240}
                height={143}
              />
              {/* --------------- */}

              <div className="w-full h-full border border-brand/20  p-[10px] lg:p-[30px] bg-white ">
                {/* --------------- */}
                <img
                  className="h-[35px] w-[35px] md:w-[72px] md:h-[71px] absolute top-5 left-5"
                  src={chamomile}
                  alt="chamomile"
                  width={72}
                  height={71}
                />
                {/* --------------- */}
                <div className="max-w-[765px]">
                  <img
                    className="w-full h-full"
                    src={aboutUs}
                    srcSet={`${aboutUs} 1x, ${aboutUsRetina} 2x`}
                    alt="about section main pic"
                    width={765}
                    height={828}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* text */}
          <div className="text relative z-20 lg:w-5/12 pl-3 pt-3 lg:pl-0 lg:pt-0">
            {/* --------------- */}
            <img
              className="absolute -top-4 lg:-top-10 lg:-right-9 -left-4 lg:left-auto
              w-[100px] lg:w-[190px]  scale-x-100 lg:-scale-x-100"
              src={text_frame}
              alt="text-frame"
              width={224}
              height={190}
            />
            {/* --------------- */}

            <div className="relative">
              <h3 className="leading-[30px] text-[18px] md:text-md text-brand">
                What is Lorem Ipsum?
              </h3>
              <p className="leading-[30px] text-[18px] md:text-md">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen Lorem Ipsum is
                simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen
              </p>
              <img
                className="max-w-[454px] min-w-[234px] w-[40vw] mx-auto md:mx-0 md:ml-auto -scale-x-100"
                src={tulip}
                alt="tulip"
                width={453}
                height={187}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
