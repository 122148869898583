import React from "react";
import {
  card1,
  card2,
  card3,
  card1retina,
  card2retina,
  card3retina,
  departments_crown,
  departments_frame,
  leaf,
  ribbon,
} from "../assets/images";
import DepartmentsCard from "../components/departments-card.component";
import Title from "../components/title.component";
import { useTranslation } from "react-i18next";

const data = [
  {
    id: 1,
    title: "What is Lorem Ipsum?",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen",
    img: card1,
    retinaImg: card1retina,
  },
  {
    id: 2,
    title: "What is Lorem Ipsum?",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 2500s, when an unknown printer took a galley of type and scrambled it to make a type specimen",
    img: card2,
    retinaImg: card2retina,
  },
  {
    id: 3,
    title: "What is Lorem Ipsum?",
    subtitle:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 3500s, when an unknown printer took a galley of type and scrambled it to make a type specimen",
    img: card3,
    retinaImg: card3retina,
  },
];

const Departments = () => {
  const { t } = useTranslation();
  return (
    <section
      className="department section container py-[50px]"
      id="departments"
    >
      <div className="relative">
        {/* ttile */}
        <div className="w-full">
          <Title className="mb-[10px] md:mb-[20px] capitalize">
            {t("nav.departments")}
          </Title>
          <img
            className="mx-auto"
            src={departments_crown}
            alt="departments-crown"
            width={819}
            height={43}
          />
          <img
            className="w-[60px] md:w-[129px] -mt-[10px] ml-auto md:mr-auto md:ml-0 md:-scale-x-100"
            src={leaf}
            alt="leaf"
            width={109}
            height={109}
          />
        </div>
        {/* content */}
        <div className="flex relative flex-col  gap-[40px] items-center md:flex-row justify-center xl:justify-between flex-wrap lg:p-[40px] xl:p-[70px]  mx-auto  lg:flex-nowrap">
          <img
            className="hidden lg:block absolute top-0 left-0 right-0"
            src={departments_frame}
            alt="departments_frame"
          />
          {data.map((item) => {
            return <DepartmentsCard key={item.id} data={item} />;
          })}

          {/* -------------------------- */}
          <img
            className="absolute w-[60px] md:w-[109px]
          -right-[30px]  top-2/3"
            src={leaf}
            alt="leaf"
            width={109}
            height={109}
          />
          {/* -------------------------- */}
          <img
            className="hidden md:block -z-10 absolute max-w-[230px] max-h-[307px] scale-125 -translate-x-[70%] bottom-0 left-0"
            src={ribbon}
            alt="ribbon"
            width={262}
            height={361}
          />
        </div>
      </div>
    </section>
  );
};

export default Departments;
