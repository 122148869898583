import React from "react";
import { useTranslation } from "react-i18next";
import {
  header_clouds,
  leaf,
  logo,
  text_frame,
  top_flower,
  tulip,
  HeaderCouple,
  HeaderCouple2x,
} from "../../assets/images";
// import { header_bg, header_bg_mobile } from '../../assets/images'

const Header = () => {
  const { t } = useTranslation();
  return (
    <section className="hero section relative pt-[80px] pb-[10px] md:pb-[60px]">
      {/* top flowers */}
      <div className="absolute top-0 left-0 right-0">
        <img
          className="max-w-[408px] w-[41vw] min-w-[300px] hidden md:block absolute top-0 left-0"
          src={top_flower}
          alt="top-flower"
          width={408}
          height={392}
        />
        <img
          className="max-w-[408px] w-[41vw] min-w-[300px] hidden md:block absolute top-0 right-0 -scale-x-100"
          src={top_flower}
          alt="top-flower"
          width={408}
          height={392}
        />
      </div>

      {/* content */}
      <div className="container pt-[35px] lg:pt-[55px]" id="main">
        <div className="flex flex-col items-center text-brand">
          {/* logo */}
          <div className="hidden md:block w-[134px] h-[134px] mb-[25px] lg:mb-[40px]">
            <img
              src={logo}
              alt="mme_holding"
              className="w-full h-full"
              width={134}
              height={134}
            />
          </div>
          <h1 className="text-[50px] leading-[130%] lg:leading-[100px] lg:text-3xl font-rische">
            LaStroia
          </h1>
          <span className="text-[32px] font-rische leading-[130%] lg:leading-[100px] lg:text-2xl  text-center">
            {t("hero.heading")}
          </span>
        </div>

        <div className="mt-[10px]">
          <div className="relative flex flex-col md:flex-row-reverse items-center justify-center overflow-visible">
            <div className="mt-[40px] w-full grid place-items-center relative md:static mb-[90px] md:w-1/2">
              <img
                className="max-h-[433px] max-w-[340px] md:max-h-[500px] md:max-w-[400px] lg:max-h-[600px] lg:max-w-[500px] xl:max-w-[682px]  xl:max-h-[830px] object-cover"
                src={HeaderCouple}
                srcSet={`${HeaderCouple} 1x, ${HeaderCouple2x} 2x`}
                alt="couples"
                width={682}
                height={830}
              />

              <img
                className="absolute w-full top-[100%] sm:top-[103%] md:-translate-y-full h-[30%] md:left-0 md:top-[95%] md:right-0 "
                src={header_clouds}
                alt="clouds1"
                width={1916}
                height={518}
              />
              <img
                className="absolute w-full top-[100%] sm:top-[103%] -translate-y-full md:left-0 md:top-[90%] md:right-0"
                src={header_clouds}
                alt="clouds2"
                width={1916}
                height={518}
              />
              <img
                className="absolute left-0 md:left-[80%] md:-top-10 -scale-x-100 md:scale-x-100 w-[80px] md:w-[109px]"
                src={leaf}
                alt="leaf"
                width={109}
                height={109}
              />
            </div>

            <div className="relative md:w-1/2 max-w-[580px]">
              <img
                className="absolute -left-1 -top-4 md:w-[224px] md:-top-[60px] sm:w-[140px] sm:-top-8 xs:w-[100px] xs:-top-5 w-[80px]   "
                src={text_frame}
                alt="text-frame"
                width={224}
                height={190}
              />
              <img
                className="absolute top-0 -translate-y-1/2 right-0 md:right-auto md:-translate-y-[150%] md:-scale-x-100  w-[80px] md:w-[129px]"
                src={leaf}
                alt="leaf"
                width={109}
                height={109}
              />

              <p className="leading-[30px] text-[18px] md:text-md mb-[35px] sm:ml-9 md:ml-14 xs:ml-[26px] ml-5">
                What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
                printing and typesetting industry. Lorem Ipsum has been the
                industry's standard dummy text ever since
              </p>
              <img
                className="max-w-[454px] min-w-[234px] w-[40vw] mx-auto md:mx-0"
                src={tulip}
                alt="tulip"
                width={453}
                height={187}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Header;
