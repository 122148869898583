import React, { useState } from "react";
import { leaf, ribbon } from "../assets/images";
import Title from "../components/title.component";
import { useTranslation } from "react-i18next";

const input_class =
  "border rounded-none  py-[10px] lg:py-[25px] lg:text-md bg-transparent px-[14px] lg:px-[30px]  placeholder:text-brown outline-brown";
const defaultVal = {
  name: "",
  phone: "",
  message: "",
};
const Contact = () => {
  const [loading, setloading] = useState(false);
  const [formData, setFormData] = useState(defaultVal);
  const [errorTxt, setErrorTxt] = useState("");

  const { t } = useTranslation();

  function handleChange(e) {
    const value = e.target.value;
    const name = e.target.name;

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }

  //bot token
  var telegram_bot_id = "5943491573:AAFtzhJhVnPuS79zJGgT32hE-rm8YC78lX0";
  //chat id
  var chat_id = -1001826958307;

  async function postData(url = "", data) {
    !data && setErrorTxt("Iltimos maydonlarni to'ldiring");
    try {
      const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(data),
      });
      setErrorTxt("Muvaffaqiyatli jonatildi!");
      setTimeout(() => {
        setErrorTxt("");
      }, 5000);

      return response.json();
    } catch (error) {
      setErrorTxt("Xatolik yuz berdi iltimos qayta urinib koring!");
    }
  }

  const handleSubmit = (e) => {
    setErrorTxt("");
    setloading(true);
    e.preventDefault();
    try {
      const message =
        "Website: LaStoria \nIsmi: " +
        formData.name +
        "\nTelefon: " +
        formData.phone +
        "\nIzoh: " +
        formData.message;
      postData(
        "https://api.telegram.org/bot" + telegram_bot_id + "/sendMessage",
        {
          chat_id: chat_id,
          text: message,
        }
      );
    } catch (e) {
      console.log(e, "error in submit handler");
    } finally {
      setloading(false);
      setFormData(defaultVal);
    }
  };
  return (
    <section className="contact section container py-[50px]" id="contact">
      <div className="relative">
        <img
          className="hidden md:block -z-10 absolute max-w-[230px] max-h-[307px] -scale-x-100  -translate-x-[50%] top-0 left-0 "
          src={ribbon}
          alt="ribbon"
          width={262}
          height={361}
        />
        <img
          className="hidden md:block -z-10 absolute max-w-[230px] max-h-[307px] translate-x-[50%] bottom-0 right-0"
          src={ribbon}
          alt="ribbon"
          width={262}
          height={361}
        />

        <Title className="mb-[60px] lg:mb-[100px]">
          {t("contact.heading")}
        </Title>

        <div className="flex flex-col lg:flex-row-reverse lg:gap-[35px] items-center lg:items-start justify-center">
          <div className="lg:w-1/2 max-w-[580px]">
            <h6 className="leading-[26px] lg:leading-[41px] text-lg lg:text-[34px] text-brand mb-[30px]">
              {t("contact.headingSm")}
            </h6>
            <p className="leading-[35px] text-[22px] hidden lg:block">
              What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen Lorem Ipsum is simply dummy text of the printing and
              typesetting industry. Lorem Ipsum has been the industry's standard
              dummy text ever since the 1500s, when an unknown printer took a
              galley of type and scrambled it to make a type specimen
            </p>
          </div>
          <form
            onSubmit={handleSubmit}
            className="relative flex flex-col w-full lg:w-1/2 max-w-[580px] gap-[20px]"
          >
            <img
              className="hidden lg:block w-[60px]  md:w-[109px] -mt-[10px] absolute -top-14 -left-14  md:-scale-x-100"
              src={leaf}
              alt="leaf"
              width={109}
              height={109}
            />

            <input
              required
              className={`${input_class} `}
              value={formData.name}
              type="text"
              pattern="[a-zA-Z0-9]+"
              minLength="3"
              name="name"
              placeholder={t("contact.name")}
              onChange={handleChange}
            />
            <input
              required
              className={`${input_class}`}
              minLength="7"
              pattern="^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$"
              type="number"
              value={formData.phone}
              name="phone"
              onChange={handleChange}
              placeholder={t("contact.phone")}
            />
            <textarea
              required
              minLength="10"
              value={formData.message}
              name="message"
              onChange={handleChange}
              className={`${input_class}  resize-none min-h-[160px] md:min-h-[308px]`}
              placeholder={t("contact.message")}
            ></textarea>
            <div className="flex items-center justify-between flex-wrap gap-2">
              <button
                disabled={
                  !formData.name || !formData.phone || !formData.message
                }
                className="bg-brown w-full hover:bg-white disabled:opacity-50 text-white hover:text-brown hover:cursor-pointer hover:border-brown border py-[12px] lg:py-[25px] lg:max-w-[300px] text-center "
              >
                {loading ? t("contact.sendingBtn") : t("contact.sendBtn")}
              </button>
              {errorTxt ? (
                <span className="font-poppins">{errorTxt}</span>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
// https://api.telegram.org/bot5943491573:AAFtzhJhVnPuS79zJGgT32hE-rm8YC78lX0/sendMessage?chat_id=1207636767&text=test2
