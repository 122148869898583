import React from "react";
import { logo } from "../../assets/images";
import { Link } from "react-scroll";
import { useTranslation } from "react-i18next";

const navbar_data = [
  { title: "main", to: "main" },
  { title: "departments", to: "departments" },
  { title: "about", to: "about" },
  { title: "contact", to: "contact" },
];

const Footer = ({ setActiveIndex, activeIndex }) => {
  const { t } = useTranslation();
  return (
    <div className="mt-[60px] bg-lightPink overflow-hidden">
      <div className="container">
        <div className="flex flex-col relative md:flex-row w-full  justify-center items-center md:py-[70px]">
          <div className="max-w-[100px] w-[10vw] min-w-[82px] py-[40px] md:py-[30px] md:absolute left-0 ">
            <img
              src={logo}
              alt="mme_holding"
              className="w-full h-full"
              width={100}
              height={100}
            />
          </div>

          <ul
            className={`flex flex-col items-center gap-[20px] md:flex-row md:gap-[45px]`}
          >
            {navbar_data.map((item, idx) => {
              return (
                <li
                  key={idx}
                  className={`${activeIndex === idx ? "activeLink" : ""} py-2`}
                >
                  <Link
                    onClick={(e) => {
                      setActiveIndex(idx);
                      window.localStorage.setItem("index", idx);
                    }}
                    to={item.to}
                    className="flex items-center gap-[6px] uppercase hover:cursor-pointer"
                    spy={true}
                    smooth={true}
                    offset={-50}
                    duration={500}
                  >
                    <span
                      className={`hover:text-brand font-poppins tracking-[0.07em] text-sm font-semibold  ${
                        activeIndex === idx ? "text-brand" : ""
                      }`}
                    >
                      {t(`nav.${item.title}`)}
                    </span>
                  </Link>
                </li>
              );
            })}
          </ul>

          <div className="mt-[60px] md:absolute right-0 bottom-2 mb-[30px] md:my-0">
            <p className="text-[12px] md:text-[14px] font-poppins font-medium text-center">
              {t("footer.footerMessage")}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
