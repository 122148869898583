import React from "react";
import { chamomile, leaf, ribbon, text_frame } from "../assets/images";

const VideoSection = () => {
  return (
    <section className="container">
      <div className=" relative py-[40px] w-full">
        <img
          className="hidden md:block -z-10 absolute max-w-[230px] max-h-[307px] translate-x-[50%] bottom-0 right-0"
          src={ribbon}
          alt="ribbon"
          width={262}
          height={361}
        />

        <div className="flex flex-col gap-5 md:flex-row items-center justify-center xl:gap-[35px]">
          {/* video */}
          <div className="video w-full md:w-1/2 max-w-[500px]  md:max-w-[825px] p-2 relative">
            <img
              className="absolute -top-3 -left-3
              w-[80px] sm:w-[120px] sm:-top-6 sm:-left-6 md:w-[150px] md:-top-9 md:-left-8 lg:w-[190px] lg:-top-10 lg:-left-9"
              src={text_frame}
              alt="text-frame"
              width={224}
              height={190}
            />
            {/* video */}
            <div className="w-full h-full border border-brand/20 p-2 md:bg-white">
              <img
                className="h-[35px] w-[35px]  md:w-[72px] md:h-[71px] absolute top-0 right-0"
                src={chamomile}
                alt="chamomile"
                width={72}
                height={71}
              />

              <div className="border relative pt-[65%]">
                {/* <video
                  preload="metadata"
                  controls
                  poster={video_poster}
                  className="w-full object-cover h-full"
                >
                  <source className="object-cover" type="video/mp4" src="" />
                  Sorry, your browser doesn't support embedded videos.
                </video> */}

                <iframe
                  className="md:max-w-[694px] md:w-full absolute w-full h-full top-0 bottom-0 right-0 left-0"
                  src="https://www.youtube.com/embed/zBHKT6vzh7U"
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>

          {/* text */}
          <div className="text relative md:w-4/12 self-stretch flex justify-center items-center">
            <img
              className="absolute -scale-x-100 -top-5 -right-4
              w-[116px] md:w-[150px] md:-top-9 md:-right-8 lg:w-[190px] lg:-top-10 lg:-right-9"
              src={text_frame}
              alt="text-frame"
              width={224}
              height={190}
            />

            {/* leaf */}
            <img
              className="absolute bottom-0 md:bottom-full translate-y-[75%] md:-translate-y-full left-0  
              w-[80px] md:w-[129px] -scale-x-100"
              src={leaf}
              alt="leaf"
              width={109}
              height={109}
            />
            <p className="leading-[30px] text-[18px]">
              What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VideoSection;
