import React from "react";

const DepartmentsCard = ({ data }) => {
  return (
    <div className="w-full max-w-[312px] xxl:w-full md:max-w-[456px]">
      <div className="img relative  mx-auto">
        <div className="absolute top-0 left-0 right-0 bottom-0 border-white border m-[14px]" />
        <img
          className="min-h-full min-w-full object-cover"
          src={data?.img}
          srcSet={`${data.img} 1x, ${data.retinaImg} 2x`}
          alt={data?.title}
          width={457}
          height={649}
        />
      </div>

      <div className="text mt-[30px] md:mt-[40px]">
        <h3 className="text-[18px] leading-[35px] xxl:text-xxl text-brand">
          {data?.title}
        </h3>
        <p className="text-[18px] leading-[35px] xxl:text-xxl ">
          {data?.subtitle}
        </p>
      </div>
    </div>
  );
};

export default DepartmentsCard;
