import { Navbar, Header, Footer } from "./layout";
import { useEffect, useState } from "react";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Departments from "./sections/Departments";
import VideoSection from "./sections/VideoSection";
import { useLocation } from "react-router-dom";

function App() {
  const [activeIndex, setActiveIndex] = useState(
    window.localStorage.getItem("index") || null
  );

  const { pathname } = useLocation();

  const handleScroll = () => {
    if (window.pageYOffset >= 0 && window.pageYOffset < 1900) {
      setActiveIndex(0);
    } else if (window.pageYOffset >= 1900 && window.pageYOffset < 3150) {
      setActiveIndex(1);
    } else if (window.pageYOffset >= 3150 && window.pageYOffset < 4100) {
      setActiveIndex(2);
    } else if (window.pageYOffset >= 4100 && window.pageYOffset < 4900) {
      setActiveIndex(3);
    } else {
      setActiveIndex(null);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className="">
      {pathname !== "/goldcoin" ? (
        <>
          <Navbar setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
          <main className="main overflow-hidden">
            <Header />
            <VideoSection />
            <Departments />
            <About />
            <Contact />
          </main>
          <Footer setActiveIndex={setActiveIndex} activeIndex={activeIndex} />
        </>
      ) : (
        <div className="flex justify-center items-center text-[#facc15] text-2xl h-screen bg-white">
          Gold Coin
        </div>
      )}
    </div>
  );
}

export default App;
